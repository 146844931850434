@import "../../../common/styles/variables";

.LoginSidebar {
    height: 100%;
    background: no-repeat transparent center;
    background-size: cover;
    background-image: url(../../static/img/login-sidebar.png);
    padding: 65px 65px;
}

.LoginSidebar_Title {
    font-weight: 500;
    font-size: 40px;
    color: $table-header;
    width: 100%;
    text-align: center;
}

.LoginSidebar_Title2 {
    font-weight: 800;
    font-size: 64px;
    color: $table-header;
    width: 100%;
    text-align: center;
}

.LoginSidebar_SubTitle {
    font-size: 16px;
    color: $table-header;
    width: 100%;
    text-align: center;
    opacity: 0.7;
    margin-top: 11px;
}

.LoginTemplate_Title {
    color: $light-text-primary;
    font-weight: 500;
    font-size: 48px;
    white-space: nowrap;
}

.LoginTemplate_SubTitle {
    color: $text;
    font-size: 16px;
    margin-top: 27px;
    height: 60px;
}

.LoginTemplate {
    background-color: $white;
    padding-top: 76px;   
    padding-bottom: 24px;

    &::-webkit-scrollbar {
        display: none;
    }
}

.LoginTemplateContainer{
    max-width: 63%;
}
.LoginTemplate_Icon {
    cursor: pointer;
    height: 70px;
    background: no-repeat transparent left;
    background-size: contain;
    background-image: url(../../static/img/call-center.png);
    margin-bottom: 66px;
}

.LoginTemplate_Alotech_Icon {
    cursor: pointer;
    height: 50px;
    background: no-repeat transparent left;
    background-size: contain;
    background-image: url(../../static/img/alotech.svg);
    margin-bottom: 66px;
    width: 300px;
}

.LoginTemplate_Back {
    top: 8px;
    left: -80px;
    height: 48px;
    width: 48px;
    cursor: pointer;
    position: absolute;
    background: no-repeat transparent left;
    background-size: contain;
    background-image: url(../../static/img/back.svg);
    margin-bottom: 66px;
}
.LoginTemplate_BackTitle {
    height: 48px;
    width: 48px;
    cursor: pointer;
    position: relative;
    background: no-repeat transparent left;
    background-size: contain;
    background-image: url(../../static/img/back.svg);
    margin-bottom: -10px;
    margin-right: 10px;
    display: none;
}

.LoginTemplate_Children {
    position: relative
}

.Recaptcha_Text {
    align-self: center;
    font-size: 11px;
    color:#5e5e5e;
    width: calc( (50vw * 0.63) - 48px);
}

@media screen and (max-width: 900px) {
    .LoginSidebar {
        display: none;
    }
    .Recaptcha_Text {
        width: calc( (100vw * 0.63) - 48px);
    }

    .LoginTemplate {
        padding-top: 36px;
        padding-bottom: 24px;
        width: 100vw;
    }
    .LoginTemplate_Icon {
        margin-bottom: 36px;
    }
    .LoginTemplateContainer{
        height: calc(100% + 200px);
    }
}


@media screen and (max-width: 600px) {
    .LoginTemplateContainer{
        max-width: 100%;
    }
    .LoginTemplate_BackTitle{
        display: inline-block;
    }

    .Recaptcha_Text {
        width: calc( 100vw - 32px);
    }
}

@media screen and (max-width: 1400px) {
    .LoginSidebar_Title{
        font-size: 34px;
    }
    .LoginSidebar_SubTitle{
        font-size: 14px;
        margin-top: -5px;
    }
}

@media screen and (max-width: 1250px) {
    .LoginTemplate_SubTitle{
        height: 80px;
        margin-top: 0;
    }
}

