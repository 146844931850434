.option {
    color: #6D809F;
    font-size: 12px;
    border-bottom: 1px solid #f4f5f7;
    font-family: 'Axiforma';
    padding: 7px 7px;
    font-weight: 600;
    cursor: pointer;
    &:hover{
        color: #FD581F;
    }
}