//-------------------------------------------------------------------------------
// Copyright © 2022 Valven. - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and Confidential
//-------------------------------------------------------------------------------

@import "../../styles/_variables.scss";

.fieldWrapper {
    display: inline-block;
    width: 100%;

    &:not(.outlined) {
        margin: 8px 0px;
    }

    &.captcha {
        height: 0px;
        margin: 0px;
    }
}

.textWrapper {
    position: relative;

    .iconWrapper {
        position: absolute;
        top: 25px;
        z-index: 1;
        right: 10px;
    }
}

.checkboxWrapper {
    height: 50px;
    display: flex;
    align-items: center;
}

.FormField_SwitchWrapper {
    display: flex;
    width: 100%;
}

.FormField_SwitchWrapper__Text {
    font-size: 14px;
    font-weight: 500;
    color: $text;
    display: flex;
    align-items: center;
}

.FormField_SwitchWrapper__Switch {
    margin-left: auto;
}

.FormField_SwitchDescriptionWrapper {
    border-radius: 8px;
    background-color: $list-row-hover;
    min-height: 90px;
    padding: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .FormField_SwitchLeftWrapper {
        display: flex;
        flex-direction: column;

        .FormField_Switch__Text {
            display: block;
            color: $blue;
            line-height: 1;
            font-weight: 500;
            font-size: 16px;
        }

        .FormField_Switch__Description {
            color: $text;
            line-height: 1;
            padding-top: 12px;
            letter-spacing: 0;
            font-size: 14px;
        }
    }
}

.section {
    margin-top: 8px;
    padding: 32px 32px 16px 32px;
    display: flex;
    flex-direction: column;
    gap: 24px;

    &.border {
        border-top: 1px solid $border-field;
    }

    .sectionTitle {
        display: block;
        color: $blue-home;
        font-size: 18px;
        line-height: 1;
        font-weight: 500;
        letter-spacing: 0;
    }

    .sectionDescription {
        color: $text;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0;
        white-space: pre-line;
    }
}

.FormField_Dropdown {
    width: 100%;
    display: flex;
}

.FormField_Audio {
    width: 44px;
    height: 40px;
    cursor: pointer;
    margin-left: 8px;
    background-repeat: no-repeat;
    align-self: center;
    background-image: url(../../static/img/voice/play-h.svg);
}