@font-face {
  font-family: Axiforma;
  src: url("../fonts/Axiforma_Regular.otf") format("opentype");
}

@font-face {
  font-weight: 100;
  font-family: Axiforma;
  src: url("../fonts/Axiforma_Light.otf") format("opentype");
}

@font-face {
  font-weight: 500;
  font-family: Axiforma;
  src: url("../fonts/Axiforma_Medium.otf") format("opentype");
}

@font-face {
  font-weight: 600;
  font-family: Axiforma;
  src: url("../fonts/Axiforma_SemiBold.otf") format("opentype");
}

@font-face {
  font-weight: 700;
  font-family: Axiforma;
  src: url("../fonts/Axiforma_Bold.otf") format("opentype");
}

@font-face {
  font-weight: 800;
  font-family: Axiforma;
  src: url("../fonts/Axiforma_ExtraBold.otf") format("opentype");
}
