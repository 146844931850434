.headerContainer{
    display: flex;
    justify-content: space-between;
    margin-top: 22px;
    .modalTitle{
        margin-left: 32px;
        letter-spacing: 0px;
        color: #002983;
        opacity: 1;
        h5{
            font-size: 28px;
            font: normal normal medium 28px/51px Axiforma;
            font-weight: 600;
        }
    }
    .modalCloseIcon{
        margin-top: 5px;
        margin-right: 30px;
        cursor: pointer;
        img{
            &:hover{
                filter: invert(13%) sepia(9%) saturate(5002%) hue-rotate(189deg) brightness(91%) contrast(88%);
            }
        }
    }
}
.contentContainer{
    margin-top: 6px;
    padding: 0px 25px 0px 32px;
    span{
        font-size: 16px;
        font: normal normal normal 16px/28px Axiforma;
        color: #6D809F;
    }
}
.inputContainer{
    display: flex;
    margin-top: 23px;
    padding: 0px 25px 0px 32px;
    input{
        width: 100%;
        height: 50px;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border: 1px solid #E4E4E4;
        border-radius: 4px;
        padding-left: 10px;
        font-size: 20px;
        outline: none;
    }
    .codeTime{
        margin-left: 5px;
        margin-top: 16px;
        font: normal normal medium 16px/51px Axiforma;
        color: #FD581F;
        white-space: nowrap;
    }
    .codeText{
        margin-left: 5px;
        margin-top: 16px;
        cursor: pointer;
        font: normal normal medium 12px/51px Axiforma;
        color: #6D809F;
        white-space: nowrap;
        &:hover{
            color: #FD581F;
        }
    }
}
.buttonsContainer{
    display: flex;
    justify-content: flex-end;
    padding: 0px 25px 0px 32px;
    margin-top: 33px;
    .leftButton{
        width: 143px;
        height: 53px;
        margin-right: 16px;
        background: #EAECF567 0% 0% no-repeat padding-box;
        border: 1px solid #D8DCEB;
        border-radius: 6px;
        font: normal normal 600 16px/16px Axiforma;
        letter-spacing: 0px;
        color: #1E3772;
        cursor: pointer;
        &:hover{
            background: #ededef 0% 0% no-repeat padding-box;
        }
    }
    .rightButton{
        width: 143px;
        height: 53px;
        background: #FD581F 0% 0% no-repeat padding-box;
        border: 1px solid #D8DCEB;
        border-radius: 6px;
        font: normal normal 600 16px/16px Axiforma;
        letter-spacing: 0px;
        color: #FFFFFF;
        cursor: pointer;
        &:hover{
            background: #FC301F 0% 0% no-repeat padding-box;
        }
    }
}