@import "../../styles/_variables.scss";

.dashboardButtonOrg {
  // height: 28px !important;
  min-width: 100px;
  // margin-left: 10px;
  font-size: 12px;
  color: $blue-home;
  position: relative;
  border: 1px solid $orange-tab;
  border-radius: 100px;
  background-color: $hover;
  font-weight: 500;
  // display: inline-flex;
  display: flex;
  align-items: center;
  // padding: 6px 14px 6px 10px;
  padding: 0 10px;
  cursor: pointer;

  span {
    // margin-left: 4px;
    // margin-top: 3px;
    color: #002983;
    // white-space: nowrap;
  }

  &.selected {
    padding: 6px 14px 6px 10px;
    border: 2px solid $orange-circle;
  }
}
.dashboardButton {
  // height: 28px !important;
  min-width: 100px;
  // margin-left: 10px;
  font-size: 12px;
  color: $blue-home;
  // padding: 6px 14px 6px 10px;
  padding: 0 10px;
  position: relative;
  border: 1px solid $border-field;
  border-radius: 100px;
  background-color: $hover;
  font-weight: 500;
  // display: inline-flex;
  display: flex;
  align-items: center;
  cursor: pointer;

  span {
    // margin-left: 4px;
    // margin-top: 3px;
    color: #002983;
    // white-space: nowrap;
  }

  &.selected {
    // padding: 6px 14px 6px 10px;
    padding: 0 10px;
    border: 2px solid $orange-circle;
  }
}
.dashboardButtonPopupClick {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.agentsCount {
  &::after {
    content: " Agents";
  }
}
.queuesCount {
  &::after {
    content: " Queue";
  }
}
.teamsCount {
  &::after {
    content: " Teams";
  }
}
.popupMenu {
  position: relative;
  border-radius: 12px;
  background-color: $white;
}
.title {
  padding: 16px 16px 6px 16px;
  color: #032983;
  font-size: 14px;
  font-weight: 600;
  text-align: left;
  opacity: 1;
}
.closeAction {
  cursor: pointer;
  position: absolute;
  top: 11px;
  right: 11px;
  margin-top: 3px;
}
.searchWrapper {
  width: 260px;
  margin-left: 16px;
  margin-right: 16px;
}
.loadingBox {
  height: 100%;
  display: flex;
}
.noResults {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
}
.selectAllWrapper {
  color: $blue !important;
  font-size: 16px !important;
  // margin-left: 1px !important;
  font-weight: bold !important;
  // padding-left: -11px !important;
  // padding-bottom: 8px !important;
}
.checkButtonsAreaWrapper {
  max-height: 168px;
  overflow: hidden auto;
  display: flex;
  font-size: 12px !important;
  // padding: 8px 12px 0;
  gap: 8px;
  flex-direction: column;
}
.checkboxItems {
  color: #314469;
  font-size: 14px;
  padding: 8px;
  font-weight: 500;
  margin-bottom: 8px;
}
.checkBoxSelectedDataArea {
  position: relative;
  min-height: 44px;
  background-color: #f1fbfe;
  margin-top: 16px;
  border-top: 1px solid #afddea;
}
.selectedCheckbox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 16px;
  margin-bottom: 8px;
  height: 12px;
}
.selectValues {
  display: flex;
  flex-direction: row;
  font-size: 12px !important;
  margin-left: 16px;
  color: #032983;
}
.selectedValue {
  padding-left: 4px;

  &:before {
    content: " ";
  }
}
.clearAll {
  cursor: pointer;
  color: #6d809f;
  padding: 12px !important;
  text-align: left;
  font-size: 12px;
  letter-spacing: 0px;
}
.nothingSelected {
  color: #032983;
  font-size: 12px !important;
  font-weight: 500 !important;
  padding: 12px 16px 12px 16px !important;
}
.boxWrapperForSelecteds {
  display: flex;
  position: relative;
  padding-bottom: 11px;
  flex-direction: column;
  align-items: flex-start;
}
.boxWrapperForSelectedsItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #d8dceb;
  border-radius: 200px;
  opacity: 1;
  margin-left: 16px;
  margin-bottom: 5px;
  color: #314469;
  padding: 6px;
  max-width: 260px;
}
.boxWrapperForSelectedsClear {
  cursor: pointer;
  height: 16px !important;
  margin-top: -8px;
  width: 25px;
  height: 18px !important;
}
.selectedCheckboxItemText {
  font-size: 12px !important;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.multiSelectedShowText {
  color: #314469;
  font-size: 12px;
  letter-spacing: 0px;
  opacity: 1;
  bottom: 20px;
  right: 28px;
  position: absolute;

  &:before {
    content: "+";
  }
}
@media screen and (max-width: $screen-sm) {
  .dashboardButton {
    // height: 28px;
    // margin-top: -8px;
    // margin: 4px 0px 4px 7px !important;
  }
  .dashboardButtonOrg {
    // height: 28px;
    // margin: 4px 0px 4px 7px !important;
  }
}

//FilterContainer
.dropdown {
  width: max-content;

  nav {
    position: absolute;
    display: flex;
    flex-wrap: wrap;
    top: 100%;
    background: #fff;
    border-top: unset;
    border-radius: 5px;
    opacity: 0;
    visibility: hidden;
    transition: 300ms all;
    z-index: 99;
    gap: 10px;
    padding: 10px 10px;
    border: 1px solid #ccdce6;
    box-shadow: 0 20px 40px #0000000d;

    width: calc(100% + 2px);
    left: 0;
    margin-left: -1px;

    div {
      display: flex;
      align-items: center;
      font-size: 14px;
    }
  }

  button {
    border-radius: 200px;
    height: 100%;
    display: flex;
    width: max-content;
    align-items: center;
    font-size: 14px;
    text-align: center;
    justify-content: center;
    border: unset;
    padding: 0px 15px 0 5px;
    background-color: unset;
    color: #002983;
    font-weight: 500;
    cursor: pointer;

    background: #fff url("../../static/img/drop_down_voice.svg") 98% 50%
      no-repeat padding-box;

    &:hover {
      color: #fd581f;
    }
  }

  &[class~="open"] {
    button {
      color: #fd581f;
      background: #fff url("../../static/img/drop_up_voice2.svg") 98% 50%
        no-repeat padding-box;
    }

    nav {
      opacity: 1;
      visibility: visible;
      transform: translateY(5px);
    }
  }
}
