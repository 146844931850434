@import "../../styles/variables";

.sylayout {
  display: block;
}
@media screen and (max-width: $screen-md) {
  .sylayout {
    display: none;

    &.open {
      display: block !important;
      margin-top: -25px;
    }
  }
  .gridContainer {
    height: 100%;
  }
}
