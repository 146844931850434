@import "../../styles/_variables.scss";

.InputField_Error {
  fieldset {
    border-color: $border-red !important;
  }

  label,
  p {
    color: $border-red !important;
  }
}
