@import "@alotech/common/styles/_variables.scss";

.buttonsWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.columnBoxAbsolite {
  height: 48px;
  position: absolute;
  width: 48px;
  right: 0px;
  border-left: 1px solid $outline-border;
  cursor: pointer;
}

.columnBoxAbsolite:hover {
  background-image: url(../../static/img/filter_column_org.svg);
}

.Grid_Toolbar_Settings_Title {
  color: $blue;
  font-weight: 500;
  font-size: 14px;
  padding: 16px 0px 16px 16px;
  line-height: 1;
}

.DragDropContext {
  max-height: 320px;
  overflow-y: auto;
}

.Grid_Toolbar_Settings_Item {
  display: flex;
  border: 1px solid #ccdce6;
  border-radius: 4px;
  margin-bottom: 8px;
  min-width: 228px !important;
  height: 32px !important;
  background-color: $white;

  .Grid_Toolbar_Settings_Item_Actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    padding: 5px 8px;

    .Grid_Toolbar_Settings_Pin_Button {
      font-size: 12px;
      line-height: 26px;
      margin-left: 16px;
      display: flex;
      justify-content: center;
      cursor: pointer;
    }
  }

  .Grid_Toolbar_Settings_Item_Move {
    display: flex;
    align-items: center;
    width: 20px;
    justify-content: center;
    border-left: 1px solid $select-border;
    cursor: move;
  }
}

.Grid_Toolbar_Settings_Buttons {
  margin: 8px 16px 0px 16px;
  display: flex;
  flex-direction: column;
}

.saveSettingsButton {
  height: 40px !important;
  font-size: 12px !important;
}

.restoreSystemSettings {
  height: 40px !important;
}
