@import "@alotech/common/styles/_variables.scss";

.filterTabWrapper {
  width: 100%;
  position: relative;
  border-bottom: 1px solid $outline-border;
  background-color: $white;
  min-height: 48px;
  justify-content: space-between;
  display: flex !important;
  align-items: stretch;
}
.buttonsWrapper {
  display: flex;
    flex-direction: row;
    align-items: center;
}
