$background-color: #eaf2f4;
$sidebar-text: #9bacc9;
$table-header: #314469;
$breadcrumb: #6d809f;
$text: #6d809f;
$border: #e4e7f2;
$border-field: #d8dceb;
$border-orange: #fd581f;
$border-red: #f02833;
$border-error: #f41e2e;
$second-menu-title: #566a8b;

$hover: #f7f7fb;

$list-color: #6d809f;

$dark-blue: #192b55;
$select-red: #ea3a3d;
$select-orange: #ff9900;
$select-green: #1ebe47;
$light-blue: #eff2f8;
$blue: #032983;
$blue-gray: #6d809f;
$orange: #fd741f;
$orange-selected: #ff5800;
$orange-background: #ff7400;
$orange-circle: #fd7422;
$orange-tab: #fd581f;

$dialog-title-bg: #43567b;
$list-row-hover: #f6f7f8;

$green: #00c36d;
$white: #ffffff;
$open: #1ab954;

$blue-home: #002983;
$green-home-dot: #00c36d;

$box-shadow-color: #0000001a;
$box-shadow: 0px 2px 2px $box-shadow-color;

$outline-border: #ccdce6;
$info-table-border: #ccdce6;
$info-table-row-dark: #f9fafc;
$info-table-bg: #eaf2f4;
$info-table-hover-border: #afddea;
$info-table-hover-bg: #f1fbfe;

$data-table-row-dark: #f2f5fa;
$data-table-row-deep-dark: #6d809f0a;
$empty-list-text-color: #7084ac;

$primary-main: #1e3772;
$primary-light: #6d809f;
$primary-dark: #303f9f;
$primary-background: #eaf2f4;

$button-secondary-bg: #eaecf567;
$button-secondary-bg-hover: #eaecf5cc;

$light-text-primary: #002983de;
$light-text-secondary: #6d809f;
$light-text-disabled: #00000061;

$disable-overlay-bg: #f4f5f7;

$step-passive-color: #62728f;
$step-error-color: #ff9800;

$hangup: #f02833;
$inbound: #1dbe79;
$disallow: #b8b8b8;
$talking: #00b972;
$hold: #fea628;
$calling: #b8e7e9;
$hold-call-bg: #ff4e58;
$answer-call-hover-bg: #20d688;
$disallow-hover-bg: #bdbdbd;
$hold-border-bg: #ecad28;

$field-border: #1e3772;
$field-box-shadow: #0000000d;
$field-outline: #1e89f4;
$field-outline-box-shadow: #2680eb34;

$divider-fill-color: #dadada;

$primary-button: #fd581f;
$primary-button-hover: #fc301f;
$voice-bg: #0da76126;
$voice-table-green: #0da761;
$voice-table-blue-bg: #d6e6f8;
$voice-table-hold-bg: #fcf3d9;
$voice-table-answering-bg: #f8dbde;
$disabled: #c5cad2;
$disabled-button-bg: #c5cad2;
$disabled-button-fg: #fff;
$disabled-button-border: #c5cad2;

$text-input-border: #7486a3;

$select-border: #e4e4e4;
$search-bg-color: #e3f7fc;
$search-border: #74d6f0;
$border-menu: #e3e6f1;
$popup-shadow: 0px 20px 40px #0000000d;

$label-bg1: #d8dceb4d;
$label-bg2: #d8dceb33;

$search-border: #74d6f0;
$search-bg: #e3f7fc;
$wrapup-bg: #f7eee8;
$queue-search-bg: #d8dceb3c;
$queue-box-shadow-color: #00000029;
$queue-box-shadow: inset 0px 2px 2px $queue-box-shadow-color;
$queue-box-second: #d8dceb1a;
$cancel-button-bg: #eaecf567;
$apply-blue-text-color: #4562a4;
$acl-border-color: #e2e5f0;
$checkbox-checked-green: #00b140;
$chart-divider: #dcdeea;
$section-bg: #f6f7fa;
$notification-info-border-color: #e9f5fe;
$notification-alarms-border-color: #fdeceb;
$notification-messages-border-color: #fff5e6;
$notification-info-color: #1171d9;
$notification-message-color: #ecad00;
$notification-alarm-color: #f02833;
$card-move-shadow: #03298314;
$divider: #dee1e5;
$popUpColor: #314469;
$chip-border: #e6dabc;
$chip-bg: #b9870026;
$chip-color: #b59e64;
$chart-divider: #dcdeea;
$cancel-icon-bg: #b19e70;
$queue-list-bg: #d8dceb3c;

$upload-bg: #8892a5;

$clamp-24-16: clamp(1.142857143rem, -0.2661rem + 1.444vw, 1.714285714rem);
$clamp-20-16: clamp(1.142857143rem, 0.4384rem + 0.722vw, 1.428571429rem);
$clamp-18-16: clamp(1.142857143rem, 0.7906rem + 0.361vw, 1.285714286rem);
$clamp-18-14: clamp(1rem, 0.2955rem + 0.722vw, 1.285714286rem);
$clamp-16-14: clamp(1rem, 0.6478rem + 0.361vw, 1.142857143rem);
$clamp-14-13: clamp(0.928571429rem, 0.7524rem + 0.1805vw, 1rem);
$clamp-14-12: clamp(0.857142857rem, 0.5049rem + 0.361vw, 1rem);
$clamp-38-20: clamp(1.42857142857rem, 1.0597rem + 1.2064vw, 2.71428571429rem);
$clamp-28-20: clamp(1.28571428571rem, 1.0808rem + 0.6702vw, 2rem);

$screen-xs: 0px;
$screen-sm: 600px;
$screen-md: 700px;
$screen-laptop: 1024px;
$screen-lg: 1200px;
$screen-lg2: 1280px;
$screen-xl: 1536px;

@mixin icon {
  display: block;
  background: no-repeat transparent center;
  background-size: cover;
}

@mixin ellipsize {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.h1 {
  color: $blue;
  font-size: 36px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
}

.h2 {
  color: $blue;
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
}

.h3 {
  color: $blue;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: left;
}

.regular {
  opacity: 0.8;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  color: $text;
  text-align: left;
}
