@import "./styles/_fonts.scss";

#root {
  height: 100%;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: Axiforma !important;
}

html {
  font-size: 14px;
  overflow: hidden;
  scroll-behavior: smooth;
  height: 100%;
}

body {
  background-color: #f0f0f0;
  color: #2b2b2b;
  height: 100%;
  @media screen and (max-width: 899px) {
    overflow-y: auto;
  }
}

a {
  text-decoration: none;
  color: unset;
}

/* Scrollbar style */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  display: block;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #6d809f;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: none;
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #cadbe5;
}
