//-------------------------------------------------------------------------------
// Copyright © 2022 Valven. - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and Confidential
//-------------------------------------------------------------------------------
@import "../../styles/_variables.scss";

.Slide_AddButton {
    width: 150px;
    margin-top: 8px !important;
}

.Slide_Carousel {
    display: flex;
    cursor: move;
    height: 76px;
    border-bottom: 1px solid $border;
    padding-top: 12px;
    background-color: white;
}

.Slide_Carousel_Index {
    background-color: $white;
    border: 1px solid $outline-border;
    width: 32px;
    height: 32px;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 24px;
    color: $blue-home;
}

.Slide_Carousel_Img {
    width: 75px;
    height: 50px;
    object-fit: cover;
}

.Slide_Carousel_Detail {
    color: #6D809F;
    font-size: 16px;
    flex: 1;

    .Slide_Carousel_Detail_Description {
        font-size: 14px;
    }
}

.Slide_InnerLabel {
    display: flex;
    align-items: center;
    color: #002983 !important;
    margin-bottom: 0 !important;
}

.headerText {
    color: $blue-home;
    font-weight: 500;
    font-size: 19px;
    align-items: start;
}

.SwipeBox {
    background-color: #F6F7FA;
    flex-grow: 1;
    height: 100%;
}

.SwipeBox_Item {
    height: 420px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 10px #00000029;
    margin: 16px 26px 10px 26px;
    border-radius: 12px;
    padding: 0 16px;
}

.SwipeBox_Item_Img {
    width: 100%;
    object-fit: cover;
    height: 170px;
    border-radius: 12px;
}

.SwipeBox_Item_Title {
    color: #6D809F;
    font-size: 16px;
}

.SwipeBox_Item_Description {
    color: #6D809F;
    font-size: 14px;
    margin-bottom: 8px;
}

.SwipeBox_Button {
    height: 50px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #6D809F;
    border-radius: 6px;
    color: #6D809F;
    margin-bottom: 8px;
    font-size: 15px;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
}