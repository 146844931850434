@import "@alotech/common/styles/_variables.scss";

.LoginCommon_Title {
    color: $light-text-primary;
    font-weight: 500;
    font-size: 64px;
}

.LoginCommon_SubTitle {
    color: $text;
    font-size: 16px;
    margin-top: 27px;
    height: 50px;
}

.LoginCommon_SignupWrapper {
    text-align: center;
    width: 100%;
    margin-top: 51px;
    font-weight: 500;
    color: $text;
    font-size: 18px;

    span {
        cursor: pointer;
        color: $blue-home;
        text-decoration: underline;
        font-weight: 600;
    }
}

.LoginCommon_SwitchWrapper {
    margin-top: 31px;
    margin-bottom: 11px;
    display: flex;
    width: 100%;
}

.LoginCommon_SwitchWrapper__Text {
    font-size: 12px;
    color: $text;
}

.LoginCommon_SwitchWrapper__Switch {
    margin-left: auto;
}

.LoginCommon_Button {
    margin-top: 0px !important;
}

.Recaptcha_Text {
    position: fixed;
    bottom: 0;
    font-size: 11px;
    color:#5e5e5e;
    width: calc( (50vw * 0.63) - 48px);
}

@media screen and (min-height: 780px) {

    .LoginCommon_Button {
        margin-top: 20px !important;
    }
}

@media screen and (max-width: 950px) {
    .LoginCommon_SignupWrapper{
        padding-bottom: 100px;
    }
}

@media screen and (max-width: 900px) {
    .Recaptcha_Text {
        width: calc( (100vw * 0.63) - 48px);
    }
}

@media screen and (max-width: 600px) {
    .Recaptcha_Text {
        width: calc( 100vw - 32px);
    }
}