//-------------------------------------------------------------------------------
// Copyright © 2022 Valven. - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and Confidential
//-------------------------------------------------------------------------------

@import "../../styles/_variables.scss";

.header {
    display: flex;
    justify-content: space-between;
    padding: 32px 32px 0px 32px !important;
}

.headerText {
    color: $blue-home;
    font-weight: 500;
    font-size: 22px;
    line-height: 1;
    align-items: start;
}
.headerTextDarkMode{
    color: #98A5B7;
    font-weight: 500;
    font-size: 22px;
    line-height: 1;
    align-items: start;
}
.CloseButton {
    border: none;
    background-color: #ffffff;
    cursor: pointer;
}
.CloseButtonDarkMode{
    border: none;
    background-color: #081D33;
    cursor: pointer;
}
.submitButton {
    width: 185px !important;
    min-width: 140px !important;
    margin-right: 27px !important;
    margin-left: 16px !important;
}


.cancelButton{
    background-color: $cancel-button-bg !important;
    width: 140px !important;
}

.confirmPopupWrapper {
    margin    : 20px 0px 20px 30px;
    font-size : 16px;
    text-align: left;
    color     : $text;
}

@media screen and (max-width: $screen-sm) {
    .submitButton {
        margin-right: 0px !important;
    }
}