@import "../../styles/_variables.scss";

.action {
  align-items: center;
}

.titleAction {
  align-items: flex-start;
  padding-bottom: 20px !important;
  .actionButton {
    position: absolute;
    left: 45px;
    bottom: 0px;
  }
}

.actionButton {
  text-transform: uppercase !important;
}
