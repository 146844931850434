@import "@alotech/common/styles/_variables.scss";

$drag-border: #00b972;

.Dashboard_Grid_Container {
  position: relative;
}

@media screen and (min-width: $screen-lg2) {
  .Dashboard_Grid_Container {
    height: calc(100% - 78px);
  }
}

.layout {
  width: 100%;
}
@media screen and (min-width: $screen-lg2) {
  .Layout_fullHeight {
    height: 100% !important;
  }
}
