//-------------------------------------------------------------------------------
// Copyright © 2022 Valven. - All Rights Reserved
// Unauthorized copying of this file, via any medium is strictly prohibited.
// Proprietary and Confidential
//-------------------------------------------------------------------------------

@import "../../styles/_variables.scss";

.placeholder {
    font-size: 14px;
    font-weight: 500;
    color: $text;
}

.optionImg {
    width: 36px;
    height: 36px;
    border: 1px solid $blue-gray;
    opacity: 1;
    border-radius: 50%;
    margin-right: 8px;
}

.info {
    float: right;
}

.cancelIcon {
    size: 18px;
    color: $cancel-icon-bg !important;
}