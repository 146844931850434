@import "../../styles/_variables.scss";

.cellHeader {
  color: #314469;
  font-size: 13px;
}
.EnhancedTable_Box {
  ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
    display: block;
  }
}
.Grid_Selection_Bar {
  padding: 12px 20px;
  border-bottom: 1px solid $outline-border;
  display: flex;
  align-items: center;
  gap: 16px;

  .Grid_Selection_String {
    color: $table-header;
    font-size: 16px;
    font-weight: 600;
  }
}
.Grid_Selection_Toolbar_Button {
  height: 38px;
  font-size: 14px;
  padding: 0px 23px 0px 15px;
  color: $blue-home;
  border: 2px solid $border-field;
  border-radius: 19px;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  text-align: center;
  white-space: nowrap;

  span {
    margin-left: 8px;
    margin-top: 3px;
  }

  &.selected {
    border: 2px solid $orange-circle;
    box-shadow: 0px 2px 4px #00000027;
  }

  &.delete {
    border: 0px;
    margin-left: auto;
  }
}

.popupMenu {
  .menuItem {
    padding: 8px 12px;

    &:not(:last-of-type) {
      border-bottom: 1px solid $border;
    }

    svg {
      margin-right: 13px;
    }
  }

  .menuItemNoHover {
    padding: 8px 12px;

    &:not(:last-of-type) {
      border-bottom: 1px solid $border;
    }

    svg {
      margin-right: 13px;
    }
  }

  .menuItem:hover {
    color: #fd7422;
  }

  .menuItemDarkMode {
    padding: 18px 27px;

    &:not(:last-of-type) {
      border-bottom: 1px solid #98a5b7;
    }

    svg {
      margin-right: 16px;
    }
  }

  &.horizontal {
    .menuItem {
      background-color: $white;
      padding: 9px 27px 9px 18px;
      border-radius: 200px;
      border: 2px solid $border-orange;

      &:hover {
        background-color: $hover;
      }

      &:not(:last-of-type) {
        border: 2px solid $border-orange;
      }

      svg {
        margin-right: 7px;
      }
    }
  }
}

.actionLabelNotHover {
  margin-right: 15px !important;
}
.dotStyleNotHover {
  margin-left: 5px !important;
}

.paginationWrapper {
  display: flex;
  align-items: center;
  margin-right: 16px;

  &.paginationSmall {
    margin-right: 16px;
  }

  .paginationPageSize {
    color: $primary-light;
    font-size: 14px;
    margin-right: 16px;
    &.paginationSmall {
      padding-top: 4px;
      font-size: 12px;
    }

    &.small {
      display: none;
    }
  }

  .paginationLabel {
    color: $text;
    font-size: 14px;
    margin-right: 16px;
    margin-left: 16px;
    &.small {
      font-size: 12px !important;
      padding-top: 4px;
      margin-right: 0px !important;
    }
  }
}
.Grid_Action_Button_Not_Hover {
  background-color: transparent;
  border: none;
  width: 36px;
  height: 36px;
  cursor: pointer;

  div {
      @include icon;
      width: 100%;
      height: 100%;
      background-size: auto;
      background-image: url(../../static/img/menu_dotv.svg);

      &:hover {
          background-image: url(../../static/img/menu_dot-vh.svg);
      }
  }
}

.voiceStatusCellType {
  background: $voice-table-blue-bg 0% 0% no-repeat padding-box;
  border: 1px solid $notification-info-color;
  border-radius: 200px;
  display: flex;
  flex-direction: row;
  align-items: center;
  opacity: 1;
  padding: 3px 10px 2px;
  font-size: 11px;
  font-weight: 600;
  color: $notification-info-color;
  max-width: 100px;
}
.voiceStatusCellTypeText{
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.outbountIcon {
  width: 20px;
  margin-top: 2px;
}

.statusTypes {
  margin-top: 10px !important;
  margin-left: 3px !important;
  color: #6D809F;
  font-size: 13px;
  display: flex;
  flex-direction: row;
  font-weight: 500;
  line-height: 36px;
  align-items: center;
  margin-left: 11px;
}

.billIcon {
  position: relative;
  width: 22px !important;
  height: 22px !important;
  margin-top: 4px !important;
  z-index: 10;
  margin-left: -2px;
}

.busyIcon {
  position: relative;
  //left: -8px;
  width: 22px !important;
  height: 22px !important;
  margin-top: 4px !important;
  margin-left: 2px;
  z-index: 8;
}

.busySystemIcon {
  position: relative;
  //left: -8px;
  width: 22px !important;
  height: 22px !important;
  margin-top: 4px !important;
  margin-left: 2px;
  z-index: 8;
}
