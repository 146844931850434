@import "../../../styles/_variables.scss";

.UploadField_GridPhoto {
    display: flex;
    padding: 0 12px;
}

.UploadField_Label {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin: 0 12px;
}

.UploadFile_Img {
    height: 100%;
    width: auto;

}

.UploadField_Label_Text {
    color: #FF5800;
    font-size: 14px;
    font-weight: 600;
    margin-left: 8px;
}

.Carousel {
    padding-top: 24px !important;
    background: #D8DCEB3D 0% 0% no-repeat padding-box;
}

.Carousel_Title {
    color: #002983;
    font-size: 16px;
}

.Add_Button {
    width: 150px;
}

.ButtonWrapper {
    border-bottom: 1px solid $border;
}

.officeHours {
    padding: 12px 0;

    .officeHourContainer {
        display: flex;
        gap: 16px;
        flex-direction: column;
    }

    .officeHourItem {
        display: flex;
        gap: 16px;
        align-items: center;

        .index {
            background-color: $white;
            border: 1px solid $outline-border;
            width: 48px;
            height: 48px;
            font-size: 20px;
            font-weight: 600;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 24px;
            margin-right: -8px;
            color: $blue-home;
        }

        .item {
            background-color: $white;
            flex: 1;
        }

        .delete {
            cursor: pointer;
        }
    }

    .subItem {
        display: flex;
        margin-left: 55px;
        margin-right: 40px;
        gap: 16px;
        
        .item0 {
            background-color: $white;
            flex: 0.5;
            margin-top: 4px;
            flex-basis:33%; 
        }

        .item1 {
            background-color: $white;
            flex: 1;
            margin-top: 4px;
            flex-basis:67%
        }
    }
}

.Color {
    display: flex;
    margin-top: 12px;

    .Color_Item {
        width: 245px;
        margin-right: 16px;
    }
}