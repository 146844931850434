@import "@alotech/common/styles/_variables.scss";

.dashboardToolbar {
  display: flex;
  position: relative;
  justify-content: space-between;
  flex-direction: row;
  background: white;
  border-radius: 12px;
  margin-bottom: 9px;
  border-radius: 8px;
  height: 62px !important;
  border: 1px solid $outline-border;
  box-shadow: 0px 1px 1px $field-box-shadow;
  align-items: center;
  padding-left: 8px;
  overflow: hidden;
}

.titleArea {
  font-size: 20px;
  color: $blue-home;
  white-space: nowrap;
  line-height: 1.4;
  font-weight: 600;
}

.dashboardToolbarNames {
  border-radius: 0px !important;
  padding-right: 16px;
  margin-left: 16px;
}
.dashboardToolbarName {
  display: flex;
  flex-direction: column;
  padding-top: 16px;
  padding-right: 16px;
  padding-bottom: 16px;
  margin-left: 8px;
  text-align: left;
  font: normal normal medium 36px Axiforma;
  letter-spacing: 0px;
  color: $light-text-primary;
  overflow: hidden;
  border-right: 1px solid $border;
}

.dashboardToolbarPath {
  text-align: left;
  font: normal normal normal 8px Axiforma;
  letter-spacing: 0px;
  color: $primary-light;
}

.headerNameSection {
  display: flex;
  flex-direction: row;
}

.headerIcon {
  color: white;
  display: inline-block;
  width: 18px;
  cursor: pointer;
  background: no-repeat transparent left center;
  background-size: contain;
}
